<script>
/**
 * Devices Data component
 */
import EditModal from "./update-device.vue";
import { authComputed, devicesMethods } from "@/state/helpers";
export default {
  props: [
    "devices",
    "regions",
    "provinces",
    "centres",
    "vaccintypes",
    "marques",
    "busy",
    "loaderStatus",
  ],
  components: {
    EditModal,
  },
  data() {
    return {
      device: {},
      devicesList: [],
      deviceTypes: {
        1: "REFRIGERATEUR",
        2: "CONGELATEUR",
        3: "ARMOIRES_FRIGORIFIQUE",
        4: "CHAMBRE_FROIDE",
        5: "CAMION",
        6: "SUPER_CONGELATEUR",
      },
    };
  },
  computed: {
    ...authComputed,
    fieldsComputed() {
      if (this.loggedIn.user.role != "USER") {
        return [
          { key: "serial", sortable: false, label: "Serial" },
          {
            key: "ref",
            sortable: false,
            label: this.$t("dataTable.reference.text"),
          },
          {
            key: "name",
            sortable: false,
            label: this.$t("dataTable.name.text"),
          },
          {
            key: "marque",
            sortable: false,
            label: this.$t("dataTable.marque.text"),
          },
          {
            key: "region",
            sortable: false,
            label: this.$t("dataTable.region.text"),
          },
          {
            key: "province",
            sortable: false,
            label: this.$t("dataTable.province.text"),
          },
          {
            key: "centre",
            sortable: false,
            label: this.$t("dataTable.centre.text"),
          },
          {
            key: "stockType",
            sortable: false,
            label: this.$t("dataTable.stockType.text"),
          },
          {
            key: "vaccinTypes",
            sortable: false,
            text: this.$t("dataTable.vaccinType.text"),
          },
          {
            key: "offline",
            sortable: false,
            label: this.$t("dataTable.status.text"),
          },
          {
            key: "operator",
            sortable: false,
            label: this.$t("dataTable.operator.text"),
          },
          {
            key: "type",
            sortable: false,
            label: this.$t("dataTable.type.text"),
          },
          {
            key: "actions",
            sortable: false,
            label: this.$t("dataTable.tools.text"),
            class: "tdClass",
          },
        ];
      } else {
        return [
          { key: "serial", sortable: false, label: "Serial" },
          {
            key: "ref",
            sortable: false,
            label: this.$t("dataTable.reference.text"),
          },
          {
            key: "name",
            sortable: false,
            label: this.$t("dataTable.name.text"),
          },
          {
            key: "marque",
            sortable: false,
            label: this.$t("dataTable.marque.text"),
          },
          {
            key: "region",
            sortable: false,
            label: this.$t("dataTable.region.text"),
          },
          {
            key: "province",
            sortable: false,
            label: this.$t("dataTable.province.text"),
          },
          {
            key: "centre",
            sortable: false,
            label: this.$t("dataTable.centre.text"),
          },
          {
            key: "stockType",
            sortable: false,
            label: this.$t("dataTable.stockType.text"),
          },
          {
            key: "vaccinTypes",
            sortable: false,
            text: this.$t("dataTable.vaccinType.text"),
          },
          {
            key: "offline",
            sortable: false,
            label: this.$t("dataTable.status.text"),
          },
          {
            key: "operator",
            sortable: false,
            label: this.$t("dataTable.operator.text"),
          },
          {
            key: "type",
            sortable: false,
            label: this.$t("dataTable.type.text"),
          },
        ];
      }
    },
  },
  mounted() {},
  methods: {
    ...devicesMethods,
    showModalEdit() {
      this.$bvModal.show("edit-device-modal");
    },
    hideModalEdit() {
      this.$bvModal.hide("edit-device-modal");
    },
    edit(device) {
      this.updateDevice(device)
        .then((device) => {
          this.makeToast(
            "Update device",
            device.name + " has been updated",
            "success"
          );
        })
        .catch((error) => {
          this.makeToast("Update device", error, "danger");
        });
    },
    passDevice(type, device) {
      if (type == "edit") {
        this.device = device;
        this.showModalEdit();
      } else if (type == "delete") {
        this.$swal
          .fire({
            title: this.$t("modals.askValidDelete.text"),
            text: this.$t("modals.cantRevert.text"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: this.$t("buttons.validDelete.text"),
            cancelButtonText: this.$t("buttons.cancel.text"),
            showLoaderOnConfirm: true,
            preConfirm: () => {
              this.delDevice(device.id)
                .then((response) => {
                  this.$emit("updatePage");
                  return response;
                })
                .catch((error) => {
                  this.$swal.showValidationMessage(`Request failed: ${error}`);
                });
            },
            allowOutsideClick: () => !this.$swal.isLoading(),
          })
          .then((result) => {
            if (result.isConfirmed) {
              this.$swal.fire(
                "Deleted!",
                "The device has been deleted.",
                "success"
              );
            }
          })
          .catch((error) => {
            this.makeToast("Alert Error", error, "danger");
          });
      }
    },
    makeToast(title, msg, variant) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
    rowClicked(record) {
      this.$router.push({
        name: "device",
        params: { serial: record.serial },
      });
    },
  },
  watch: {

    devices(newVal) {
      this.devicesList = [];
      newVal.map((obj) => {
        let vaccinTypes = obj.vaccinTypes.map(vaccin=> {return vaccin.name});
        this.devicesList.push({
          ...obj,
          region: obj.region.name,
          marque: obj.marque.name,
          province: obj.province.name,
          centre: obj.centre ? obj.centre.name : null,
          operator: obj.operator ? obj.operator == 0 ? "ORANGE" : obj.operator == 1 ? "IAM" : obj.operator == 2 ? "INWI" : "" : "",
          vaccinTypes: vaccinTypes ? vaccinTypes.toString() : "-"
        });
      });
    },
  },
};
</script>

<template>
  <div
    class="table-responsive mb-3 bg-white rounded-lg"
    style="min-height: 430px"
  >
    <b-table
      class="mb-0"
      hover
      show-empty
      centred
      :busy="busy"
      :empty-text="$t('dataTable.noDevices.text')"
      :items="devicesList"
      :fields="fieldsComputed"
      v-bind:style="{ cursor: `pointer` }"
      stacked="sm"
      @row-clicked="rowClicked"
    >
      <template #table-busy>
        <div class="text-center my-2">
          <strong>{{ $t("dataTable.loading.text") }}</strong>
        </div>
      </template>
      <template v-slot:cell(actions)="data">
        <div class="d-inline-flex align-items-center">
          <b-button
            type="button"
            class="mr-2"
            variant="light"
            @click="passDevice('edit', devices[data.index])"
            >{{ $t("buttons.edit.text") }}
          </b-button>
          <b-button
            type="button"
            variant="danger"
            @click="passDevice('delete', devices[data.index])"
            >{{ $t("buttons.delete.text") }}
          </b-button>
        </div>
      </template>
      <template v-slot:cell(type)="row">
        <div class="font-size-14 text-center font-weight-bold">
          {{deviceTypes[row.item.type]}}
        </div>
      </template>
      <template v-slot:cell(operator)="row">
        <div 
          v-if="row.item.operator == 'ORANGE'"
          class="font-size-14 text-center font-weight-bold" style="color:#ffa238;"
        >
            {{row.item.operator}}
        </div>
        <div 
          v-if="row.item.operator == 'IAM'"
          class="font-size-14 text-center font-weight-bold" style="color:#2c2cf1;"
        >
            {{row.item.operator}}
        </div>
        <div 
          v-if="row.item.operator == 'INWI'"
          class="font-size-14 text-center font-weight-bold" style="color:#cf2aff;"
        >
            {{row.item.operator}}
        </div>
      </template>
      <template v-slot:cell(offline)="row">
        <div class="font-size-14 text-center font-weight-bold">
          <i
            v-if="row.item.offline == false"
            class="text-success ri-checkbox-blank-circle-fill"
          ></i>
          <i
            v-if="row.item.offline == true || row.item.offline == null"
            class="text-secondary ri-checkbox-blank-circle-fill"
          ></i>
        </div>
      </template>
    </b-table>
    <EditModal
      v-bind:device="device"
      @editDevice="edit"
      :regions="regions"
      :marques="marques"
      :provinces="provinces"
      :centres="centres"
      :vaccintypes="vaccintypes"
    />
  </div>
</template>
<style>
.tdClass {
  text-align: center;
}
</style>