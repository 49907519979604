<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

import DevicesData from "./devices-data";
import AddDevice from "./add-device";
import FilterDevices from "./filter-devices";

import {
  layoutComputed,
  authComputed,
  devicesMethods,
  regionsMethods,
  marquesMethods,
  provincesMethods,
  centresMethods,
  vaccintypesMethods,
  devicesComputed,
  regionsComputed,
  marquesComputed,
  provincesComputed,
  centresComputed,
  vaccintypesComputed,
} from "@/state/helpers";
/**
 * Devices component
 */
export default {
  page: {
    title: "Devices",
    meta: [{ name: "Devices" }],
  },
  components: {
    Layout,
    PageHeader,
    DevicesData,
    AddDevice,
    FilterDevices,
  },
  mounted() {
    this.retrieveDevices({}).then(async () => {
      if (this.getMarques.results) this.marquesData = this.getMarques.results;
      else await this.retrieveMarques();
      if (this.getRegions.results) this.regionsData = this.getRegions.results;
      else await this.retrieveRegions();
      if (this.getVaccintypes.results)
        this.vaccintypesData = this.getVaccintypes.results;
      else await this.retrieveVaccinTypes();
      if (this.getProvinces.results)
        this.provincesData = this.getProvinces.results;
      else await this.retrieveProvinces();
      if (this.getCentres.results)
        this.centresData = this.getCentres.results;
      else await this.retrieveCentres();
    });
  },
  data() {
    return {
      title: "titles.devices.text",

      busy: true,

      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50],

      loaderDashboard: false,

      devicesData: [],
      regionsData: [],
      marquesData: [],
      provincesData: [],
      centresData: [],
      vaccintypesData: [],

      devicesFilter: {
        serial: null,
        ref: null,
        name: null,
        type: null,
        operator: null,
        stockTypes: null,
        regions: null,
        marques: null,
        provinces: null,
        vaccinTypes: null,
      },
      searchStatus: false,
    };
  },
  computed: {
    ...layoutComputed,
    ...authComputed,
    ...devicesComputed,
    ...regionsComputed,
    ...provincesComputed,
    ...centresComputed,
    ...vaccintypesComputed,
    ...marquesComputed,

    items() {
      return [
        {
          text: "Nextrack",
        },
        {
          text: this.$t("titles.devices.text"),
          active: true,
        },
      ];
    },
  },
  methods: {
    ...devicesMethods,
    ...regionsMethods,
    ...provincesMethods,
    ...centresMethods,
    ...vaccintypesMethods,
    ...marquesMethods,

    retrieveDevices({
      perPage,
      page,
      serial,
      ref,
      name,
      type,
      operator,
      stockTypes,
      regions,
      marques,
      provinces,
      vaccintypes,
    }) {
      let params = {
        page: page ? page : this.currentPage,
        limit: perPage ? perPage : this.perPage,
        serial: serial ? serial : this.devicesFilter.serial,
        ref: ref ? ref : this.devicesFilter.ref,
        name: name ? name : this.devicesFilter.name,
        type: type ? type : this.devicesFilter.type,
        operator: operator ? operator : this.devicesFilter.operator,
        stockTypes: stockTypes ? stockTypes : this.devicesFilter.stockTypes,
        regions: regions ? regions : this.devicesFilter.regions,
        provinces: provinces ? provinces : this.devicesFilter.provinces,
        vaccinTypes: vaccintypes ? vaccintypes : this.devicesFilter.vaccinTypes,
        marques: marques ? marques : this.devicesFilter.marques,
      };
      let paramsFilter = this.cleanObject(params);
      this.busy = true;
      return this.getDevicesBy(paramsFilter).then((devices) => {
        this.busy = false;
        return devices;
      });
    },
    retrieveRegions() {
      return this.getRegionsBy({ limit: 100 }).then((regions) => {
        return regions;
      });
    },
    retrieveMarques() {
      return this.getMarquesBy({ limit: 100 }).then((marques) => {
        return marques;
      });
    },
    retrieveVaccinTypes() {
      return this.getVaccintypesBy({ limit: 100 }).then((vaccintypes) => {
        return vaccintypes;
      });
    },
    retrieveProvinces() {
      return this.getProvincesBy({ limit: 100 }).then((provinces) => {
        return provinces;
      });
    },
    retrieveCentres() {
      return this.getCentresBy({ limit: 6000, populate: 'province,region' }).then((centres) => {
        return centres;
      });
    },
    openAdd() {
      this.showModalAdd();
    },
    makeToast(title, msg, variant) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
    search(params) {
      this.currentPage = 1;
      this.devicesFilter = {
        ...this.devicesFilter, 
        ref: params.ref,
        type: params.type,
        operator: params.operator,
        stockTypes: params.stockTypes,
        regions: params.regions,
        marques: params.marques,
        provinces: params.provinces,
        vaccinTypes: params.vaccintypes,
      }
      let cleanObject = this.cleanObject(params)
      this.retrieveDevices({ cleanObject, page: 1 }).then((devices) => {
        if (devices.results.length < 1) {
          this.hideModalFilter();
          this.makeToast("Search...", "Device not found !", "danger");
        } else {
          this.hideModalFilter();
        }
      });
    },
    clearSearch() {
      this.currentPage = 1;
      (this.devicesFilter = {
        ...this.devicesFilter,
        serial: null,
        name: null,
      }),
        this.retrieveDevices({ ...this.devicesFilter, page: 1 }).then(() => {
          this.searchStatus = false;
        });
    },
    showModalFilter() {
      this.$bvModal.show("filter-modal");
    },
    hideModalFilter() {
      this.$bvModal.hide("filter-modal");
    },
    handlePageChange(value) {
      this.currentPage = value;
      this.retrieveDevices({ ...this.devicesFilter });
    },
    showModalAdd() {
      this.$bvModal.show("add-device-modal");
    },
    hideModalAdd() {
      this.$bvModal.hide("add-device-modal");
    },
    add(device) {
      this.addDevice(device)
        .then((device) => {
          this.makeToast(
            "Add device",
            device.name + " has been added",
            "success"
          );
        })
        .catch((error) => {
          this.makeToast("Add device", error, "danger");
        });
    },
    updatePage() {
      let total = this.getDevices.results.length;
      let current = this.currentPage;
      if (total < 1 && current > 1) {
        this.currentPage -= 1;
      }
      this.retrieveDevices({ ...this.devicesFilter });
    },
    cleanObject(obj) {
      Object.keys(obj).forEach((key) => {
        if (obj[key] === null || obj[key] === undefined || obj[key] === "") {
          delete obj[key];
        }
      });
      return obj;
    },
    async exportAllDevices(){
      await this.exportAllDevicesXLS();
    }
  },
  watch: {
    "devicesFilter.name"(newVal) {
      this.search({ ...this.devicesFilter, name: newVal });
    },
    "devicesFilter.serial"(newVal) {
      this.search({ ...this.devicesFilter, serial: newVal });
    },
    getDevices(newVal) {
      this.devicesData = newVal.results;
    },
    getRegions(newVal) {
      this.regionsData = newVal.results;
    },
    getMarques(newVal) {
      this.marquesData = newVal.results;
    },
    getVaccintypes(newVal) {
      this.vaccintypesData = newVal.results;
    },
    getProvinces(newVal) {
      this.provincesData = newVal.results;
    },
    getCentres(newVal) {
      this.centresData = newVal.results;
    },
  },
  beforeDestroy() {},
};
</script>

<template>
  <Layout>
    <PageHeader :title="$t(title)" :items="items" />
    <div class="row">
      <div class="col-6 col-md-5 d-inline-flex align-items-center">
        <b-form-input
          id="stateShSerial"
          v-model="devicesFilter.serial"
          :placeholder="$t('textInput.enterSerial.text')"
          debounce="1500"
          class="mr-2"
        ></b-form-input>
        <b-form-input
          id="stateShName"
          v-model="devicesFilter.name"
          :placeholder="$t('textInput.enterName.text')"
          debounce="1500"
          class="mr-2"
        ></b-form-input>
        <b-button
          v-if="devicesFilter.name || devicesFilter.serial"
          type="button"
          class="mr-2"
          variant="warning"
          @click="clearSearch"
          ><i class="ri-refresh-line"></i>
        </b-button>
      </div>
      <div
        v-if="loggedIn.user.role != 'USER'"
        class="col-4 col-md-5"
      >
        <b-button type="button" class="mr-2" variant="light" @click="openAdd"
          >{{ $t("buttons.addDevice.text") }}
        </b-button>
        <AddDevice
          @addDevice="add"
          :regions="regionsData"
          :marques="marquesData"
          :provinces="provincesData"
          :centres="centresData"
          :vaccintypes="vaccintypesData"
        />
      </div>
      <div class="col-2 col-md-2 text-right">
        <b-button variant="light" class="mr-2" @click="exportAllDevices">
          <i class="ri-download-line"></i>
        </b-button>
        <b-button variant="light" @click="showModalFilter">
          <i class="ri-filter-3-line"></i>
        </b-button>
        <FilterDevices
          @filter="search"
          :regionsData="regionsData"
          :marquesData="marquesData"
          :provincesData="provincesData"
          :vaccintypesData="vaccintypesData"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <DevicesData
          v-bind:devices="devicesData"
          :busy="busy"
          :regions="regionsData"
          :marques="marquesData"
          :provinces="provincesData"
          :centres="centresData"
          :vaccintypes="vaccintypesData"
          @updatePage="updatePage"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="dataTables_paginate paging_simple_numbers float-right mb-3">
          <ul class="pagination pagination-rounded mb-0">
            <!-- pagination -->
            <b-pagination
              v-model="currentPage"
              :total-rows="getDevices.totalResults"
              :per-page="perPage"
              @change="handlePageChange"
            ></b-pagination>
          </ul>
        </div>
      </div>
    </div>
  </Layout>
</template>